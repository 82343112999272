<template>
    <a
        :href="url"
        target="_blank"
        class="h-full bg-white p-4 base-responsive-rounding flex flex-col justify-between shadow-md"
    >
        <span class="font-Poppins font-semibold mb-4">{{ title }}</span>
        <div class="flex items-center justify-between">
            <span class="auxiliary-text">{{ size }}</span>
            <Button :label="$t('generic.download')" class="p-button-secondary p-button-text" icon="pi pi-download" />
        </div>
    </a>
</template>

<script>
export default {
    name: 'DocumentCard',
    props: {
        url: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        size: {
            type: String,
            required: true,
        },
    },
};
</script>

<style scoped></style>
