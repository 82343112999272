import { render, staticRenderFns } from "./ProgramCard.vue?vue&type=template&id=63827e6c&scoped=true&"
import script from "./ProgramCard.vue?vue&type=script&lang=js&"
export * from "./ProgramCard.vue?vue&type=script&lang=js&"
import style0 from "./ProgramCard.vue?vue&type=style&index=0&id=63827e6c&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "63827e6c",
  null
  
)

export default component.exports