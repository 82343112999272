<template>
    <div class="swiper-container">
        <div ref="swiper" class="swiper swiper--xxl">
            <div class="swiper-wrapper">
                <div
                    v-for="(image, i) in images"
                    :key="`gallery-image-${i}`"
                    class="swiper-slide cursor-pointer"
                    @click="() => openLightbox(i)"
                >
                    <img
                        class="object-cover w-full h-full"
                        :src="image.src"
                        :alt="`${$t('generic.gallery_image_alt_text')} ${i}`"
                    />
                </div>
            </div>
            <SwiperNavigationButton v-if="images.length >= 2" prev overflow @click.native="prev" />
            <SwiperNavigationButton v-if="images.length >= 2" next overflow @click.native="next" />
        </div>

        <div
            v-if="images.length >= 2"
            class="carousel-pagination-block container relative mt-6 sm:mt-8 lg:mt-12 grid grid-cols-12 base-responsive-gap"
        >
            <div
                class="col-span-12 xl:col-span-10 xl:col-start-2 2xl:col-span-12 2xl:col-start-1 flex items-center justify-center sm:justify-end"
            >
                <div ref="prevBtn" class="swiper-button-prev"></div>
                <span class="gallery-pagination-counter">01</span>
                <div ref="pagination" class="swiper-pagination"></div>
                <span class="gallery-pagination-counter">{{ String(images.length).padStart(2, '0') }}</span>
                <div ref="nextBtn" class="swiper-button-next"></div>
            </div>
        </div>

        <LightboxGalleryCarousel ref="lightbox" :items="images" />
    </div>
</template>

<script>
import SwiperNavigationButton from '@/components/UI/SwiperNavigationButton.vue';
import LightboxGalleryCarousel from '@/components/Carousels/LightboxGalleryCarousel/LightboxGalleryCarousel.vue';

export default {
    name: 'XXLGalleryCarousel',
    components: {
        SwiperNavigationButton,
        LightboxGalleryCarousel,
    },
    props: {
        images: {
            type: Array,
            required: true,
        },
    },
    mounted() {
        this.carousel = new this.$swiper(this.$refs.swiper, {
            spaceBetween: 16,
            slidesPerView: 1.2,
            centeredSlides: true,
            loop: this.images.length >= 2,
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
            },
            breakpoints: {
                640: {
                    spaceBetween: 30,
                    slidesPerView: 1.35,
                },
                1920: {
                    spaceBetween: 30,
                    slidesPerView: 1.41,
                },
            },
        });
    },
    beforeDestroy() {
        this.carousel.destroy();
    },
    methods: {
        prev() {
            this.carousel.slidePrev();
        },
        next() {
            this.carousel.slideNext();
        },
        openLightbox(slideIndex) {
            this.$refs.lightbox.open(slideIndex);
        },
    },
};
</script>

<style>
.swiper.swiper--xxl .swiper-slide {
    @apply h-[300px] sm:h-[400px] lg:h-[500px] xl:h-[600px] 2xl:h-[700px] 3xl:h-[40vw] rounded-xl lg:rounded-[40px] overflow-hidden;
    @apply opacity-50 transition-opacity duration-[500ms];

    &.swiper-slide-active {
        opacity: 1;
    }
}

/*

.gallery-thumbnails .swiper-slide {
    @apply opacity-80;
}
#gallery {
    @apply mb-[30px];

    .swiper-slide {
        @apply aspect-w-16 aspect-h-9;

        img {
            @apply object-cover;
        }
    }
}

#gallery-thumbnails {
    .swiper-slide {
        img {
            @apply object-cover;
        }
    }
}

*/
</style>
