<template>
    <article class="h-full base-responsive-rounding relative bg-blue-500 bg-theme-dark">
        <div class="grid grid-cols-12 gap-3 sm:gap-5 relative z-1">
            <div class="col-span-12 relative lg:col-span-5">
                <a :href="url" target="_blank" class="block relative lg:h-full base-responsive-rounding">
                    <div class="thumbnail aspect-w-5 aspect-h-3 lg:aspect-w-[none] lg:aspect-h-[none] lg:h-full">
                        <img :src="img" :alt="title" />
                    </div>
                    <EventSeriesBadge />
                </a>
            </div>

            <div
                class="col-span-12 lg:col-span-7 pr-2 pl-2 pt-2 lg:pt-6 lg:pr-4 lg:pl-0 pb-4 flex flex-col justify-between"
            >
                <div class="w-full lg:min-h-[10.25rem]">
                    <a :href="url" target="_blank" class="hover:underline">
                        <h3 class="font-Poppins text-2xl font-semibold line-clamp-3 lg:line-clamp-2">
                            {{ title }}
                        </h3>
                    </a>
                    <p class="font-bold text-lg mt-2">{{ lead }}</p>
                    <p v-if="description" class="line-clamp-4 mt-3">{{ description }}</p>
                </div>
                <div class="w-full flex flex-col sm:flex-row lg:flex-col xl:flex-row justify-between mt-5">
                    <div class="flex">
                        <EventCategoryBadge
                            v-for="(eventType, i) in eventTypes"
                            :key="eventType"
                            :type="eventType"
                            :class="{ 'ml-2': i > 0 }"
                        />
                    </div>

                    <div class="flex flex-wrap justify-between mt-3 sm:mt-0 lg:mt-4 xl:mt-0 -mx-2">
                        <Button
                            :label="$t('generic.view')"
                            :to="url"
                            target="_blank"
                            class="p-button-secondary p-button-text mx-2 my-1"
                            icon="pi pi-chevron-right"
                        />
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import EventCategoryBadge from '~/components/UI/EventCategoryBadge.vue';
import EventSeriesBadge from '~/components/UI/EventSeriesBadge.vue';

export default {
    name: 'ProgramSeriesCard',
    components: {
        EventCategoryBadge,
        EventSeriesBadge,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        lead: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        url: {
            type: String,
            required: true,
        },
        eventTypes: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.program-card__separator-line {
    height: 1px;
    @apply w-16 lg:w-full;
}

.bg-gradient-overlay {
    background: linear-gradient(0deg, #000 4.44%, rgba(0, 0, 0, 0) 85.09%);
    @media (min-width: 1024px) {
        background: linear-gradient(270deg, #000 4.44%, rgba(0, 0, 0, 0) 85.09%);
    }
}
</style>
