<template>
    <div>
        <Portal selector="body">
            <Transition name="fade">
                <div
                    v-if="isOpen"
                    class="swiper-container z-50 fixed top-0 left-0 w-full h-screen bg-black/90 pt-16 pb-12 sm:py-12 overflow-auto"
                >
                    <Button btn-close class="absolute top-4 right-4" @click.native="close" />
                    <div class="container relative max-h-screen bg-theme-dark bg-theme-dark--swiper z-1">
                        <div ref="swiper" class="swiper swiper--lightbox">
                            <div class="swiper-wrapper relative">
                                <div
                                    v-for="(item, i) in items"
                                    :key="`gallery-image-${i}`"
                                    class="swiper-slide flex items-center justify-center"
                                >
                                    <img
                                        class="max-h-full max-w-full sm:rounded-4xl"
                                        :src="item.src"
                                        :alt="`${$t('generic.gallery_image_alt_text')} ${i}`"
                                    />
                                </div>
                            </div>

                            <div class="flex flex-col-reverse sm:flex-col">
                                <div
                                    class="carousel-pagination-block container relative mt-6 sm:mt-8 grid grid-cols-12 base-responsive-gap"
                                >
                                    <div
                                        class="col-span-12 xl:col-span-10 xl:col-start-2 2xl:col-span-12 2xl:col-start-1 flex items-center justify-center"
                                    >
                                        <div ref="prevBtn" class="swiper-button-prev"></div>
                                        <span class="gallery-pagination-counter">01</span>
                                        <div ref="pagination" class="swiper-pagination"></div>
                                        <span class="gallery-pagination-counter">{{
                                            String(items.length).padStart(2, '0')
                                        }}</span>
                                        <div ref="nextBtn" class="swiper-button-next"></div>
                                    </div>
                                </div>
                            </div>

                            <SwiperNavigationButton prev @click.native="prev" />
                            <SwiperNavigationButton next @click.native="next" />
                        </div>

                        <div
                            v-if="items[activeSlideIndex].title || items[activeSlideIndex].description"
                            class="text-white pt-4 text-center"
                        >
                            <h3 v-if="items[activeSlideIndex].title" class="font-bold mb-1">
                                {{ items[activeSlideIndex].title }}
                            </h3>
                            <p v-if="items[activeSlideIndex].description">{{ items[activeSlideIndex].description }}</p>
                        </div>
                    </div>
                </div>
            </Transition>
        </Portal>
    </div>
</template>

<script>
import SwiperNavigationButton from '@/components/UI/SwiperNavigationButton.vue';
export default {
    name: 'XXLGalleryCarousel',
    components: {
        SwiperNavigationButton,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isOpen: false,
            activeSlideIndex: 0,
        };
    },
    beforeDestroy() {
        if (this.carousel) {
            this.carousel.destroy();
        }
        window.removeEventListener('keyup', this.escapeListener);
    },
    methods: {
        open(initialSlideIndex) {
            this.isOpen = true;
            this.activeSlideIndex = initialSlideIndex || 0;

            setTimeout(() => {
                this.carousel = new this.$swiper(this.$refs.swiper, {
                    spaceBetween: 16,
                    slidesPerView: 1,
                    centeredSlides: true,
                    initialSlide: this.activeSlideIndex,
                    loop: true,
                    navigation: {
                        nextEl: this.$refs.nextBtn,
                        prevEl: this.$refs.prevBtn,
                    },
                    pagination: {
                        el: this.$refs.pagination,
                        clickable: true,
                    },
                });

                this.carousel.on('slideChange', () => {
                    this.activeSlideIndex = this.carousel.realIndex;
                });

                window.addEventListener('keyup', this.escapeListener);
            }, 50);
        },
        close() {
            this.isOpen = false;
        },
        escapeListener(e) {
            if (e.key === 'Escape') {
                this.isOpen = false;
            }
        },
        prev() {
            this.carousel.slidePrev();
        },
        next() {
            this.carousel.slideNext();
        },
    },
};
</script>

<style>
.swiper.swiper--lightbox .swiper-slide {
    @apply h-[calc(100vh-350px)] min-h-[200px] sm:h-[calc(100vh-270px)] sm:min-h-[300px] 2xl:h-[calc(100vh-270px)] 2xl:min-h-[400px];
}

/*

.gallery-thumbnails .swiper-slide {
    @apply opacity-80;
}
#gallery {
    @apply mb-[30px];

    .swiper-slide {
        @apply aspect-w-16 aspect-h-9;

        img {
            @apply object-cover;
        }
    }
}

#gallery-thumbnails {
    .swiper-slide {
        img {
            @apply object-cover;
        }
    }
}

*/
</style>
