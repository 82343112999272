<template>
    <div id="video-gallery-container" class="pt-4">
        <div class="container">
            <h2 class="header-1"><T t="the_project.videos" /></h2>

            <div class="grid grid-cols-12 base-responsive-gap">
                <div class="col-span-12 md:col-span-6 lg:col-span-8">
                    <div class="separator-border-top-line"></div>
                </div>
            </div>

            <div class="grid grid-cols-12 base-responsive-gap mt-4">
                <div class="col-span-12 md:col-span-6 lg:col-span-8">
                    <div class="aspect-w-16 aspect-h-9">
                        <iframe
                            width="100%"
                            height="100%"
                            :src="`https://www.youtube.com/embed/${activeVideo.video_url}`"
                            title="YouTube video player"
                            frameborder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                            allowfullscreen
                        ></iframe>
                    </div>
                </div>
                <div class="col-span-12 md:col-span-6 lg:col-span-4">
                    <h3 class="header-3">{{ activeVideo.title }}</h3>
                    <div class="mt-4" v-html="activeVideo.lead_text"></div>
                </div>
            </div>
        </div>

        <div v-if="videos.length" class="mt-12 sm:mt-28">
            <CardCarousel
                type="video"
                :programmatic-active-slide-index="activeVideoIndex"
                :items="videos"
                @update-active-index="onUpdateActiveInstitutionIndex"
            />
        </div>

        <div class="text-center mt-16 lg:mt-16">
            <Button
                to="https://www.youtube.com/channel/UCQnLn4FHzg7nj5LJ6w5voKA"
                target="_blank"
                :label="$t('the_project.visit_youtube_channel_button_label')"
            />
        </div>
    </div>
</template>

<script>
import CardCarousel from '@/components/Carousels/CardCarousel/CardCarousel.vue';

export default {
    name: 'VideoGalleryBlock',
    components: {
        CardCarousel,
    },

    props: {
        videos: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            activeVideoIndex: 0,
        };
    },

    computed: {
        activeVideo: {
            get() {
                return this.videos[this.activeVideoIndex];
            },
            set() {},
        },
    },
    mounted() {
        this.activeVideo = this.videos[0];
    },
    methods: {
        onUpdateActiveInstitutionIndex(index) {
            // if (this.$device.isMobile) {
            this.$scrollToElement('#video-gallery-container', window.innerWidth < 1024 ? 30 : 0, 'auto');
            // }

            this.$nextTick(() => {
                this.activeVideoIndex = index;
            });
        },
    },
};
</script>

<style scoped></style>
