<template>
    <article class="h-full base-responsive-rounding relative" :class="featured ? 'bg-black bg-theme-dark' : 'bg-white'">
        <div class="grid grid-cols-12 gap-3 sm:gap-5 relative z-1">
            <div class="col-span-12 relative" :class="featured ? 'lg:col-span-5' : 'lg:col-span-4'">
                <a :href="url" target="_blank" class="block relative lg:h-full base-responsive-rounding">
                    <div class="thumbnail aspect-w-5 aspect-h-3 lg:aspect-w-[none] lg:aspect-h-[none] lg:h-full">
                        <img :src="img" :alt="title" />
                    </div>
                    <EventSeriesBadge v-if="hasSeries" />
                </a>
            </div>

            <div
                class="col-span-12 pr-2 pl-2 lg:pt-6 lg:pr-4 lg:pl-0 pb-4"
                :class="featured ? 'lg:col-span-7' : 'lg:col-span-8'"
            >
                <div class="flex flex-col xl:flex-row xl:items-center">
                    <div class="font-Poppins font-bold sm:text-lg">
                        {{ date }}
                    </div>

                    <div
                        v-if="location"
                        class="font-Poppins sm:text-lg uppercase xl:max-w-[calc(100%-250px)] relative xl:pl-4 xl:ml-4"
                    >
                        <span
                            class="hidden xl:inline-block absolute left-0 top-1 border-l border-black h-[calc(100%-0.5rem)]"
                        ></span>
                        {{ location }}
                    </div>
                </div>
                <div class="lg:min-h-[10.25rem]">
                    <div class="flex flex-col-reverse lg:flex-col mb-1 lg:mb-2 mt-2">
                        <a :href="url" target="_blank" class="program-card__title">
                            <h3 class="font-Poppins text-lg sm:text-xl font-semibold line-clamp-3 lg:line-clamp-2">
                                {{ title }}
                            </h3>
                        </a>
                        <div class="program-card__separator-line bg-black"></div>
                    </div>
                    <p v-if="description" class="line-clamp-4">{{ description }}</p>
                </div>
                <div class="flex flex-col sm:flex-row lg:flex-col xl:flex-row justify-between mt-2 sm:mt-4 lg:mt-5">
                    <div v-if="withCategoryIcons" class="flex">
                        <EventCategoryBadge
                            v-for="(eventType, i) in eventTypes"
                            :key="eventType"
                            :type="eventType"
                            :class="{ 'ml-2': i > 0 }"
                        />
                    </div>
                    <div
                        class="flex flex-wrap justify-between mt-3 sm:mt-0 lg:mt-4 xl:mt-0 -mx-2"
                        :class="{ 'w-full': !withCategoryIcons }"
                    >
                        <Button
                            :label="$t('generic.view')"
                            :to="url"
                            target="_blank"
                            class="p-button-secondary p-button-text mx-2 my-1"
                            icon="pi pi-chevron-right"
                        />
                        <Button
                            v-if="ticketUrl"
                            :label="$t('generic.purchase_ticket')"
                            :to="ticketUrl"
                            target="_blank"
                            class="mx-2 my-1"
                        />
                        <Button
                            v-if="registrationUrl"
                            :label="$t('event.register')"
                            :to="registrationUrl"
                            target="_blank"
                            class="mx-2 my-1"
                        />
                    </div>
                </div>
            </div>
        </div>
    </article>
</template>

<script>
import EventCategoryBadge from '~/components/UI/EventCategoryBadge.vue';
import EventSeriesBadge from '~/components/UI/EventSeriesBadge.vue';

export default {
    name: 'ProgramCard',
    components: {
        EventSeriesBadge,
        EventCategoryBadge,
    },
    props: {
        featured: {
            type: Boolean,
            required: false,
            default: false,
        },
        withCategoryIcons: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        img: {
            type: String,
            required: true,
        },
        ticketUrl: {
            type: String,
            required: false,
            default: null,
        },
        registrationUrl: {
            type: String,
            required: false,
            default: null,
        },
        url: {
            type: String,
            required: true,
        },
        date: {
            type: String,
            required: true,
        },
        location: {
            type: String,
            required: false,
            default: null,
        },
        eventTypes: {
            type: Array,
            required: true,
        },
        hasSeries: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped>
.program-card__title {
    @apply relative mt-2 lg:mt-0 lg:mb-2;
}
.program-card__separator-line {
    height: 1px;
    @apply w-16 lg:w-full;
}

.bg-gradient-overlay {
    background: linear-gradient(0deg, #000 4.44%, rgba(0, 0, 0, 0) 85.09%);
    @media (min-width: 1024px) {
        background: linear-gradient(270deg, #000 4.44%, rgba(0, 0, 0, 0) 85.09%);
    }
}
</style>
