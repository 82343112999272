<template>
    <div class="overflow-hidden">
        <div class="container">
            <div class="grid grid-cols-12 gap-y-4 lg:gap-y-0 lg:gap-x-7" style="grid-template-rows: auto 1fr">
                <div class="col-span-12 lg:col-span-4 separator-border-top-line">
                    <h2 class="header-2 mb-0 sm:mb-4">
                        <T v-if="titleKey" :t="titleKey" />
                        <span v-else>{{ title }}</span>
                    </h2>
                </div>

                <div class="swiper-container swiper-container--type-2-block col-span-12 lg:col-span-8 lg:row-span-2">
                    <div
                        class=""
                        :class="
                            images.length === 1
                                ? ''
                                : 'lg:w-[calc(100%+(100vw-992px)/2)] xl:w-[calc(100%+(100vw-1140px)/2)] 2xl:w-[calc(100%+(100vw-1440px)/2)] 3xl:w-[calc(100%+(30vw)/2)]'
                        "
                    >
                        <div
                            ref="swiper"
                            class="swiper mx-[-10px] px-[10px] sm:mx-[calc((100vw-600px)/2*-1)] sm:px-[calc((100vw-600px)/2)] lg:mx-0 lg:pl-0 lg:pr-12"
                        >
                            <div class="swiper-wrapper">
                                <div
                                    v-for="(image, i) in images"
                                    :key="`gallery-image-${i}`"
                                    class="swiper-slide cursor-pointer"
                                    :style="images.length === 1 ? 'opacity: 1; width: 100%' : ''"
                                    @click="() => openLightbox(i)"
                                >
                                    <img
                                        :src="image.src"
                                        :alt="`${titleKey ? $t(titleKey) : title} ${$t(
                                            'generic.gallery_image_alt_text'
                                        )} ${i}`"
                                        class="base-responsive-rounding w-full h-full object-cover"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-if="images.length >= 2" class="carousel-pagination-block relative mt-6 sm:mt-8 lg:mt-12">
                        <div class="flex items-center justify-center sm:justify-end">
                            <div ref="prevBtn" class="swiper-button-prev"></div>
                            <span class="gallery-pagination-counter">01</span>
                            <div ref="pagination" class="swiper-pagination"></div>
                            <span class="gallery-pagination-counter">{{ String(images.length).padStart(2, '0') }}</span>
                            <div ref="nextBtn" class="swiper-button-next"></div>
                        </div>
                    </div>
                </div>

                <div class="col-span-12 lg:col-span-4">
                    <div class="whitespace-pre-line">
                        <T v-if="descriptionKey" :t="descriptionKey" />
                        <div v-else>{{ description }}</div>
                    </div>
                </div>
            </div>
        </div>

        <LightboxGalleryCarousel ref="lightbox" :items="images" />
    </div>
</template>

<script>
import LightboxGalleryCarousel from '../../Carousels/LightboxGalleryCarousel/LightboxGalleryCarousel.vue';

export default {
    name: 'Type2Block',
    components: {
        LightboxGalleryCarousel,
    },
    props: {
        titleKey: {
            type: String,
            required: false,
            default: null,
        },
        descriptionKey: {
            type: String,
            required: false,
            default: null,
        },
        title: {
            type: String,
            required: false,
            default: null,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
        images: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            carousel: null,
        };
    },
    mounted() {
        if (this.images.length === 1) {
            return;
        }

        this.carousel = new this.$swiper(this.$refs.swiper, {
            spaceBetween: 16,
            slidesPerView: 1.2,
            centeredSlides: false,
            loop: false,
            navigation: {
                nextEl: this.$refs.nextBtn,
                prevEl: this.$refs.prevBtn,
            },
            pagination: {
                el: this.$refs.pagination,
                clickable: true,
            },
            breakpoints: {
                640: {
                    spaceBetween: 20,
                    slidesPerView: 1,
                },
                768: {
                    spaceBetween: 20,
                    slidesPerView: 1.2,
                },
                1280: {
                    spaceBetween: 30,
                    slidesPerView: 1.2,
                },
            },
        });
    },
    beforeDestroy() {
        if (this.carousel) {
            this.carousel.destroy();
        }
    },
    methods: {
        openLightbox(slideIndex) {
            this.$refs.lightbox.open(slideIndex);
        },
    },
};
</script>

<style scoped>
.swiper .swiper-slide {
    width: unset;
    @apply h-[calc(80vw-20px)] sm:h-[350px] md:h-[400px] lg:h-[425px] 3xl:h-[30vw] flex items-center justify-center;
    @apply opacity-50 transition-opacity duration-[500ms];
    &.swiper-slide-active {
        opacity: 1;
    }
}
</style>
