<template>
    <div class="grid grid-cols-12 base-responsive-gap">
        <div class="col-span-12 separator-border-top-line" :class="{ 'lg:col-span-10 lg:col-start-2': isNarrow }">
            <div class="flex items-center justify-between mb-8 sm:mb-12">
                <h2 class="header-2">{{ title }}</h2>
                <div class="hidden md:flex">
                    <Button
                        :to="secondaryBtnUrl"
                        target="_blank"
                        :label="secondaryBtnLabel"
                        class="p-button-secondary p-button-outlined mr-8"
                    />
                    <Button :to="btnUrl" target="_blank" :label="btnLabel" />
                </div>
            </div>
            <div class="grid grid-cols-12 gap-x-5 gap-y-6 lg:gap-y-14">
                <a
                    v-for="item in items"
                    :key="`liget-plus-item-${item.id}`"
                    :href="item.link"
                    target="_blank"
                    class="col-span-12 md:col-span-6 xl:col-span-4 flex items-start liget-plus-item"
                >
                    <div
                        v-if="item.media.image"
                        class="w-[3rem] h-[3rem] min-w-[3rem] min-h-[3rem] md:w-[4.5rem] md:h-[4.5rem] md:min-w-[4.5rem] md:min-h-[4.5rem] bg-contain bg-center bg-no-repeat mr-4"
                        v-lazy-background :lazy-background="item.media.image.sm"
                    ></div>
                    <div>
                        <h3 class="uppercase text-body font-bold">
                            {{ item.title }}
                        </h3>
                        <p>{{ item.lead }}</p>
                    </div>
                </a>
            </div>
            <div class="flex flex-col sm:flex-row md:hidden mt-8">
                <Button
                    :to="secondaryBtnUrl"
                    target="_blank"
                    :label="secondaryBtnLabel"
                    class="p-button-secondary p-button-outlined mb-4 sm:mb-0 sm:mr-8"
                />
                <Button :to="btnUrl" target="_blank" :label="btnLabel" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LigetPlusDetailsBlock',
    props: {
        isNarrow: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        btnLabel: {
            type: String,
            required: true,
        },
        btnUrl: {
            type: String,
            required: true,
        },
        secondaryBtnLabel: {
            type: String,
            required: true,
        },
        secondaryBtnUrl: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.liget-plus-item:hover h3 {
    @apply underline;
}
</style>
