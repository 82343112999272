<template>
    <div class="bg-white" :style="`box-shadow: 0 0.5rem 0.5rem -0.25rem rgba(0, 0, 0, 0.1);`">
        <div class="container overflow-auto pb-1">
            <div class="tabs flex border-b border-white">
                <button
                    v-for="(item, i) in items"
                    :key="`internal-nav-item-${i}`"
                    :data-target="item.url"
                    class="tab-link relative px-4 py-2 whitespace-nowrap"
                    :class="{ 'tab-link--active': activeHash === item.url }"
                    @click="$emit('update-active-hash', item.url)"
                >
                    {{ item.name }}
                </button>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InternalNavBar',
    props: {
        activeHash: {
            type: String,
            required: true,
        },
        items: {
            type: Array,
            required: true,
        },
    },
};
</script>

<style scoped>
.tab-link::after {
    content: '';
    @apply block absolute bottom-[-5px] left-0 w-full h-[5px] bg-blue-400 z-1 rounded-full opacity-0 transition-opacity;
}

.tab-link.tab-link--active {
    @apply font-bold;
}

@media (hover: hover) {
    .tab-link:hover::after {
        @apply opacity-100;
    }
}
.tab-link.tab-link--active::after {
    @apply opacity-100;
}
</style>
