<template>
    <div class="bg-gray-200">
        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Layers tab (sticky) */}}
        {{/* * */}}

        <div
            v-if="layers.length > 1"
            class="sticky left-0 w-full z-2 bg-white shadow-lg"
            :style="`top: ${stickyElementTopPosition}`"
        >
            <div class="container">
                <div class="overflow-auto flex w-full justify-between pt-2 -mt-0.5">
                    <button
                        v-for="layer of layerTabItems"
                        :key="`layer-${layer.id}`"
                        class="layer-button flex flex-col items-center relative px-4 pb-3"
                        :class="{ active: activeLayerIDs.includes(layer.id) }"
                        @click="() => toggleLayer(layer.id)"
                    >
                        <span
                            class="layer-button__icon w-8 h-8 bg-cover bg-no-repeat bg-center mb-1 sm:mb-2"
                            :style="`background-image: url(${layer.icon})`"
                        ></span>
                        <span
                            class="text-xs sm:text-sm font-bold uppercase whitespace-nowrap"
                            :class="activeLayerIDs.includes(layer.id) ? 'text-black' : 'text-gray-400'"
                        >
                            {{ layer.title }}
                        </span>
                    </button>
                </div>
                <div></div>
            </div>
        </div>

        {{/* * */}}
        {{/* * */}}
        {{/* * */}}
        {{/* Main container. */}}
        {{/* A flex row container of 3 .relative divs. The 2 on the sides are 0px wide and serve as */}}
        {{/* .relative parents for their .sticky child elements (the navigation and the currently selected place's info card). */}}
        {{/* The one in the center (the map itself) fills out the entire container. */}}
        {{/* * */}}
        <div class="relative lg:flex z-1">
            {{/* * */}}
            {{/* Pictogram explainer info card & button group on the left */}}
            <div class="absolute h-full lg:h-unset lg:relative z-2 w-0 lg:py-12">
                <div
                    class="absolute lg:sticky top-6 lg:top-1/2 lg:translate-x-full left-4 lg:left-0 -mr-12 transition-all flex"
                    @click="unselectActiveMarker"
                >
                    {{/* * */}}
                    {{/* Pictogram explainer info card */}}
                    {{/* The pictogram explainer info card panel was requested to be removed by the client */}}
                    <div
                        v-if="false"
                        class="transition-all overflow-visible"
                        :class="{
                            'max-w-[calc(100vw-5.25rem)] sm:max-w-[18rem] mr-4': isPictogramExplanationInfoPanelVisible,
                            'max-w-0 opacity-0 pointer-events-none': !isPictogramExplanationInfoPanelVisible,
                        }"
                    >
                        <div
                            class="relative bg-white p-4 pr-8 w-[calc(100vw-5.25rem)] sm:w-[18rem] base-responsive-rounding shadow-lg"
                        >
                            <div class="absolute top-2 right-2">
                                <Button btn-close @click.native="hidePictogramExplanationInfoPanel" />
                            </div>

                            <div class="grid grid-cols-1 gap-4 max-h-[calc(100vh-14rem)] overflow-auto">
                                <Transition v-for="layer in layers" :key="`pictogram-info-${layer.id}`" name="fade">
                                    <div
                                        v-show="activeLayerIDs.includes(layer.id) && layer.pictograms.length"
                                        class="grid grid-cols-1 gap-4"
                                    >
                                        <div
                                            v-for="(pictogram, j) in layer.pictograms"
                                            :key="`pictogram-${j}`"
                                            class="flex items-center"
                                        >
                                            <span
                                                class="w-8 h-8 min-w-[2rem] bg-contain bg-center bg-no-repeat mr-4"
                                                :style="`background-image: url(${pictogram.icon})`"
                                            >
                                            </span>
                                            <span class="text-sm font-semibold uppercase">{{ pictogram.title }}</span>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>

                    {{/* * */}}
                    {{/* Button group on the left (info & zoom buttons) */}}
                    <div class="flex flex-col lg:justify-center transition-all">
                        {{/* The pictogram explainer info card panel was requested to be removed by the client */}}
                        <Button
                            v-if="activeLayerIDs.length && false"
                            class="p-button-sm p-button-secondary transition-all"
                            :class="{
                                'opacity-0 pointer-events-none': isPictogramExplanationInfoPanelVisible,
                            }"
                            :style="`${isPictogramExplanationInfoPanelVisible ? 'max-height: 0' : ''}`"
                            icon="pi pi-info"
                            @click.native="showPictogramExplanationInfoPanel"
                        />
                        <Button
                            class="p-button-sm mt-2"
                            icon="pi pi-plus"
                            :disabled="scale === 3"
                            @click.native="zoomIn"
                        />
                        <Button
                            class="p-button-sm mt-2"
                            icon="pi pi-minus"
                            :disabled="scale === 1"
                            @click.native="zoomOut"
                        />
                    </div>
                </div>
            </div>

            {{/* * */}}
            {{/* The map */}}
            <div class="relative z-1" style="-webkit-overflow-scrolling: touch; flex: 1">
                <div
                    class="liget-map-container overflow-hidden z-1 relative lg:h-[60vw]"
                    :class="[fullscreen ? 'h-[calc(100vh-3.5rem)]' : 'h-[70vh]', zooming ? 'zooming' : '']"
                >
                    <div
                        ref="panzoomContainer"
                        class="pan-zoom-container relative lg:min-h-[60vw]"
                        :class="fullscreen ? 'min-h-[calc(100vh-3.5rem)]' : 'min-h-[70vh]'"
                    >
                        {{/* Main image layer. Fetched as a static image from the api */}}
                        <StaticImage
                            :img-key="`2d-map-base-${$i18n.locale}`"
                            full-screen
                            image-class="z-1 absolute w-full h-full object-cover grabbable"
                            @click="unselectActiveMarker"
                        />

                        {{/* Image layers */}}
                        <Transition v-for="layer in layers" :key="`layer-image-${layer.id}`" name="fade">
                            <img
                                v-show="layer.layer_image && activeLayerIDs.includes(layer.id)"
                                :src="layer.layer_image"
                                :alt="`${layer.title} layer`"
                                :class="{ 'mix-blend-multiply': !layer.liget_future }"
                                class="z-2 absolute w-full h-full object-cover grabbable"
                                @click="unselectActiveMarker"
                            />
                        </Transition>

                        <Transition v-for="marker in markers" :key="`marker-${marker.id}`" name="fade">
                            <button
                                v-if="isTheLayerOfTheMarkerActive(marker.id)"
                                class="marker z-3"
                                :class="{
                                    'opacity-0': zooming,
                                    'z-10': focusedMarkerID === marker.id,
                                }"
                                :style="`transition: opacity 0.1s, transform 0.3s; transform: translate(-50%, -50%) scale(${markerScale}); top: ${
                                    marker.position.top
                                }%; left: ${marker.position.left}%; background-image: url(${
                                    getMarkerPictogramData(marker.pictogram_id).icon
                                })`"
                                @click="(e) => handleMarkerClick(e, marker.id)"
                            >
                                <span
                                    class="marker-label"
                                    :class="[
                                        marker.isLabelReversed ? 'reversed' : '',
                                        focusedMarkerID === marker.id
                                            ? 'opacity-100 pointer-events-auto -top-1'
                                            : 'opacity-0 pointer-events-none',
                                    ]"
                                >
                                    <span
                                        class="marker-label-icon"
                                        :style="`background-image: url(${
                                            getMarkerPictogramData(marker.pictogram_id).icon
                                        })`"
                                    ></span>
                                    <span class="marker-label-text">
                                        {{ getMarkerPictogramData(marker.pictogram_id).title }}
                                    </span>
                                </span>
                            </button>
                        </Transition>
                    </div>
                </div>
            </div>

            <div
                class="absolute lg:relative top-0 right-0 w-full h-full z-2 lg:w-0 lg:h-auto transition-opacity lg:opacity-100 lg:pointer-events-auto lg:py-12"
                :class="activePlaceIndex === -1 ? 'opacity-0 pointer-events-none' : 'opacity-100'"
            >
                <div v-if="fullscreen" class="absolute w-full h-full bg-black/30" @click="unselectActivePlace"></div>
                {{/* * */}}
                {{/* Selected place card */}}
                <div
                    class="absolute lg:sticky top-4 sm:top-12 lg:top-[33vh] w-full transition-all lg:transition-none"
                    :class="{ 'translate-y-4 lg:translate-y-unset': activePlaceIndex === -1 }"
                >
                    <div class="sm:w-96 px-4 sm:px-0 mx-auto lg:mr-unset lg:-ml-12 lg:-translate-x-full">
                        <div
                            v-for="(place, i) in placeCardsData"
                            :key="`place-card-${place.category_id}-${place.id}`"
                            :data-id="`place-card-${place.category_id}-${place.id}`"
                        >
                            <MapPlaceCard
                                v-show="i === activePlaceIndex"
                                :close="unselectActivePlace"
                                :description="place.image_text"
                                :img="$getMediaImage(place.media, 'image')"
                                :url="place.url"
                                :cta-label="place.link_text"
                                :title="place.title"
                                :recommended-program="place.related_program"
                                class="shadow-lg"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import panzoom from 'panzoom';
import { debounce } from 'throttle-debounce';
import MapPlaceCard from '@/components/Cards/MapPlaceCard/MapPlaceCard.vue';
import announcement from '@/mixins/announcement';

export default {
    name: 'Map2D',
    components: {
        MapPlaceCard,
    },
    mixins: [announcement],
    props: {
        layers: {
            type: Array,
            required: true,
        },
        fullscreen: {
            type: Boolean,
            required: false,
            default: false,
        },
        isFirstMenuItemHidden: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            activeLayerIDs: [], // layers that are selected to be visible
            activePlaceIndex: -1, // active place = the marker you clicked on, resulting in a place card being displayed on the right
            isPictogramExplanationInfoPanelVisible: false,
            scale: 1,
            zooming: false,
            focusedMarkerID: null,
        };
    },
    computed: {
        markerScale() {
            // reverse scale for markers
            return 1 / this.scale;
        },
        layerTabItems() {
            const _layers = this.$clone(this.layers);

            if (this.isFirstMenuItemHidden) {
                _layers.shift();
            }

            return _layers;
        },
        markers() {
            return this.layers.reduce((acc, curr) => {
                if (!curr.markers) {
                    return acc;
                }

                curr.markers.forEach((marker) => {
                    acc.push(marker);
                });
                return acc;
            }, []);
        },
        // Place cards appear on the right side of the map.
        // Generated from the markers that aren't supposed to show label only
        placeCardsData() {
            return this.layers.reduce((acc, curr) => {
                curr.markers.forEach((marker) => {
                    if (!marker.is_label_only) {
                        marker.category_id = curr.id;
                        acc.push(marker);
                    }
                });

                return acc;
            }, []);
        },
        // Pictograms appear on the left, in the pictogram explanation info panel
        pictograms() {
            return this.layers.reduce((acc, curr) => {
                if (!curr.pictograms) {
                    return acc;
                }

                curr.pictograms.forEach((x) => acc.push(x));
                return acc;
            }, []);
        },
    },
    watch: {
        // Hide Active place card on the right when user clicks on a map layer tab
        activeLayerIDs() {
            this.activePlaceIndex = -1;
        },
    },
    mounted() {
        // On mount, always make the first layer active
        this.activeLayerIDs.push(this.layers[0].id);

        // Pan zoom stuff
        this.panzoom = panzoom(this.$refs.panzoomContainer, {
            bounds: true,
            boundsPadding: 1,
            minZoom: 1,
            maxZoom: 3,
            beforeWheel: (e) => !e.altKey,
            onTouch: function (e) {
                // `e` - is current touch event.
                return false; // tells the library to not preventDefault.
            },
        });

        this.panzoom.on('zoom', (e) => {
            this.zooming = true;

            // Sometimes, the zoomend event doesn't fire after the last zoom event,
            // and the markers remain hidden.
            // To prevent this, we make sure that this.zooming is set to true 300ms after the last zoom event,
            // forcing the markers to re-appear, no matter what.
            this.resetZoomingState(e);
        });

        this.panzoom.on('zoomend', (e) => {
            this.zooming = false;

            this.scale = e.getTransform().scale;
        });

        if (this.$device.isMobile) {
            document.querySelector('body').style.overscrollBehavior = 'none';
        }
    },
    beforeDestroy() {
        document.querySelector('body').style.overscrollBehavior = null;
    },
    methods: {
        resetZoomingState: debounce(300, false, function (e) {
            this.zooming = false;
            this.scale = e.getTransform().scale;
        }),
        zoomIn() {
            if (this.scale >= 2.95) {
                return;
            }
            this.panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 2);
        },
        zoomOut() {
            if (this.scale <= 1.05) {
                return;
            }
            this.panzoom.smoothZoom(window.innerWidth / 2, window.innerHeight / 2, 0.5);
        },
        isTheLayerOfTheMarkerActive(markerID) {
            const layersContainingTheMarker = this.layers.filter((x) =>
                x.markers.find((marker) => marker.id === markerID)
            );
            return !!layersContainingTheMarker.find((layer) => this.activeLayerIDs.find((id) => id === layer.id));
        },
        getMarkerPictogramData(pictogramID) {
            return this.pictograms.find((pictogram) => pictogram.id === pictogramID);
        },
        showPictogramExplanationInfoPanel() {
            this.isPictogramExplanationInfoPanelVisible = true;
        },
        hidePictogramExplanationInfoPanel() {
            this.isPictogramExplanationInfoPanelVisible = false;
        },
        toggleLayer(layerID) {
            // The logic is: the Liget future layer is something special, so if the user
            // clicks on it, we want the map to display only that layer. It will have a solid background and will
            // always be the last element in the array, so it will cover all other layers. Therefore,
            // if the user wants to select other layers, we need to hide the Liget future layer so that
            // those layers can become visible. In short, the Liget future layer is either not displayed, or
            // it is displayed exclusively and nothing else.
            const ligetFutureLayer = this.layers.find((x) => x.liget_future);

            if (!this.activeLayerIDs.includes(layerID)) {
                // on layer selection

                if (ligetFutureLayer && layerID === ligetFutureLayer.id) {
                    this.activeLayerIDs = [layerID];
                } else {
                    // eslint-disable-next-line
                    if (ligetFutureLayer) {
                        this.activeLayerIDs = this.activeLayerIDs.filter((x) => x !== ligetFutureLayer.id);
                    } else if (layerID !== this.layers[0].id) {
                        this.activeLayerIDs = [this.layers[0].id, layerID];
                    }
                }
            } else if (layerID !== this.layers[0].id) {
                // on layer unselection

                this.activeLayerIDs = this.activeLayerIDs.filter((x) => x !== layerID);
            }

            if (
                !this.activeLayerIDs.length ||
                (ligetFutureLayer && !this.activeLayerIDs.includes(ligetFutureLayer.id))
            ) {
                this.activeLayerIDs.push(this.layers[0].id);
            }
        },
        handleMarkerClick(e, ID) {
            const marker = this.markers.find((x) => x.id === ID);

            if (this.focusedMarkerID === ID) {
                this.focusedMarkerID = null;
                this.activePlaceIndex = -1;
                return;
            }

            if (!marker.is_label_only) {
                this.activePlaceIndex = this.placeCardsData.indexOf(marker);
                this.focusedMarkerID = null;
            } else {
                this.activePlaceIndex = -1;

                // if marker label would span out of the screen when opened to the right of
                // the marker (because marker is on the far right of the screen), open it
                // to the left side of the marker instead
                this.markers.find((x) => x.id === ID).isLabelReversed =
                    e.target.getBoundingClientRect().right +
                        20 +
                        e.target.firstElementChild.getBoundingClientRect().width >
                    window.innerWidth;
                this.focusedMarkerID = ID;
            }
        },
        unselectActiveMarker() {
            this.unselectActivePlace();
            this.focusedMarkerID = null;
        },
        unselectActivePlace() {
            this.activePlaceIndex = -1;
        },
    },
};
</script>

<style scoped>
.pan-zoom-container {
    @media (max-width: 1024px) {
        aspect-ratio: 5 / 3;
    }
}
.layer-button {
    filter: grayscale(1);
    &.active {
        filter: grayscale(0);
    }
}

.marker {
    @apply absolute w-10 h-10 bg-contain bg-center bg-no-repeat -translate-x-1/2 -translate-y-1/2;
}

.marker-label {
    @apply absolute inline-flex items-center top-0 left-0 rounded-3xl cursor-default transition-all pointer-events-none;
    @apply bg-blue-400 text-white whitespace-nowrap;
    @apply py-2 pl-2 pr-4;
}

.marker-label-icon {
    @apply w-6 h-6 bg-contain bg-no-repeat bg-center mr-2;
}

.marker-label-text {
    @apply text-sm font-semibold uppercase;
}

.marker .marker-label.reversed {
    flex-direction: row-reverse;
    left: unset;
    right: 0;
    @apply pr-2 pl-4;

    .marker-label-icon {
        @apply mr-0 ml-2;
    }
}

.liget-map-container.zooming .marker {
    transform: scale(0) !important;
}

.grabbable {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
    cursor: -moz-grab;
    cursor: -webkit-grab;
}

/* (Optional) Apply a "closed-hand" cursor during drag operation. */
.grabbable:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
}
</style>
