<template>
    <div
        class="grid grid-cols-12 gap-0 md:gap-x-5 lg:gap-x-7 container-overflow-forbidden"
        style="grid-template-rows: auto 1fr"
    >
        <div class="col-span-12 md:col-span-5 lg:col-span-4 lg:col-start-2 md:row-span-2 order-2 md:order-1">
            <picture>
                <source media="(min-width:992px)" :srcset="media.image.lg" />
                <source media="(min-width:480px)" :srcset="media.image.md" />
                <img :src="media.image.sm" :alt="title" />
            </picture>
        </div>
        <div class="col-span-12 md:col-span-7 lg:col-span-6 wysiwyg-content order-1 md:order-2">
            <h2 class="header-2">{{ title }}</h2>
        </div>
        <div v-if="description" class="col-span-12 md:col-span-7 lg:col-span-6 wysiwyg-content order-3 mt-8 md:mt-0">
            <div v-html="description"></div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AwardsBlock',
    props: {
        media: {
            type: Object,
            required: true,
        },
        title: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
            default: null,
        },
    },
};
</script>

<style scoped></style>
