<template>
    <ClientOnly>
        <div
            class="newsletter-form relative"
            :class="{ 'newsletter-form--card bg-gray-200 p-4 lg:p-10 base-responsive-rounding': card }"
        >
            <div v-if="card && !game" class="header-3 mb-8">
                További izgalmas tartalmakért iratkozz fel hírlevelünkre!
            </div>

            <div id="hubspotForm1" class="hbspt-form"></div>
        </div>
    </ClientOnly>
</template>

<script>
export default {
    name: 'NewsletterHubspotForm',
    props: {
        card: {
            type: Boolean,
            required: false,
            default: false,
        },
        game: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    mounted() {
        // If we are on the melkweg test site, we don't want to render the hubspot form
        // in order to avoid warnings in the hubspot admin console (client's request)
        if (/melkweg/.test(window.location.host)) {
            return;
        }

        const hubspotLocaleConfig = {};

        if (this.$i18n.locale === 'hu') {
            hubspotLocaleConfig.locale = 'hu';
            hubspotLocaleConfig.translations = {
                hu: {
                    required: 'A mező kitöltése kötelező',
                    invalidEmailFormat: 'Hibás e-mail formátum',
                    invalidEmail: 'Érvénytelen e-mail cím.',
                    resubscribeMessage: 'Kérjük, kattintson ide és erősítse meg újra feliratkozási szándékát',
                    submissionErrors: {
                        MISSING_REQUIRED_FIELDS: 'Töltsön ki minden kötelező mezőt',
                        BLOCKED_EMAIL: 'Kérjük, a folytatáshoz módosítsa e-mail címét.',
                    },
                },
            };
        }

        if (this.game) {
            window.hbspt.forms.create({
                portalId: '6087627',
                formId: '66701d7e-e636-43f8-ac69-246e5d044659',
                target: '#hubspotForm1',
            });
        } else {
            window.hbspt.forms.create({
                portalId: '6087627',
                formId: '3495bd9b-720d-4bd4-a20f-37d826826261',
                target: '#hubspotForm1',
                ...hubspotLocaleConfig,
            });

            setTimeout(() => {
                document.querySelector('.hs-button').addEventListener('click', function () {
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                        event: 'eventGA',
                        eventCategory: 'newsletter_subscription',
                        eventAction: ' click_to_subscribe',
                        eventLabel: ' nem_megerositett_feliratkozas',
                        formLocation: 'newsletter_form',
                    });
                });
            }, 1500);
        }
    },
};
</script>

<style>
.hbspt-form {
    legend.hs-field-desc {
        @apply mb-4 font-bold;
    }
    input[type='text'],
    input[type='email'] {
        @apply w-full sm:w-96 bg-white border text-black border-gray-200;
        font-size: 1rem;
        padding: 0.5rem;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        appearance: none;
        border-radius: 8px;
        &::placeholder {
            @apply text-gray-400;
        }
        &:not(:disabled) {
            &:hover {
                @apply border-gray-600;
            }
            &:focus {
                outline: 0 none;
                outline-offset: 0;
                @apply text-black border-black;
            }
        }
    }
    .hs-form-booleancheckbox-display {
        display: flex !important;
        align-items: flex-start;
    }
    input[type='checkbox'] {
        width: 1.25rem !important;
        height: 1.25rem !important;
        min-width: 1.25rem !important;
        min-height: 1.25rem !important;
        margin-top: 2px;
        border: 1px solid #495057;
        background: #fff;
        color: #495057;
        border-radius: 999px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        cursor: pointer;
        position: relative;
        &:checked {
            &::before {
                content: '\e909';
                @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
                font-family: 'primeicons', serif;
                speak: none;
                font-size: 0.5rem;
                color: #fff;
            }
            background-color: #000;
            border-color: #000;
        }
    }
    input[type='submit'] {
        @apply text-sm text-white font-Poppins font-bold border border-blue-500 px-5 py-2 bg-blue-500 uppercase rounded-full cursor-pointer mt-4;
        letter-spacing: 1px;
        transition: background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s;
        &:focus {
            outline: 0 none;
            outline-offset: 0;
        }
        &:not(:disabled) {
            @media (hover: hover) {
                &:hover {
                    @apply bg-blue-400 border-blue-400 text-white;
                }
            }
        }
    }
    .hs-error-msg {
        @apply text-sm text-error;
    }
    .hs-form-field {
        margin-bottom: 1rem;
        label {
            display: inline-block;
            margin-bottom: 0.5rem;
        }
    }
    .hs_liget_budapest_feliratkozo_kampany___nyeremenyjatek_reszvetel,
    .legal-consent-container {
        .hs-form-booleancheckbox-display > span {
            margin-left: 0.5rem;
        }
        a {
            text-decoration: underline;
        }
    }
}

/*
.newsletter-form--card .hbspt-form {
    input[type='text'],
    input[type='email'] {
        @apply sm:w-full;
    }
    .hs-fieldtype-text {
        display: inline-block;
        width: calc(33.33% - 1rem);
    }
    .hs_firstname,
    .hs_lastname {
        margin-right: 1.5rem;
    }
    .legal-consent-container {
        width: calc(100% - 20rem);
    }
    .legal-consent-container .hs-form-booleancheckbox-display {
        margin-top: 1rem;
    }
    .hs_submit {
        @apply absolute top-32 right-0;
    }
}
*/
</style>
