<template>
    <div>
        <div
            class="grid grid-cols-12 grid-rows-16 sm:grid-rows-14 lg:grid-rows-16 base-responsive-gap h-[600px] sm:h-[800px]"
        >
            <div
                v-if="items[0]"
                class="mosaique-img-container thumbnail col-span-7 row-span-4 sm:col-span-7 sm:row-span-8"
                @click="() => openLightbox(0)"
            >
                <img v-lazy-load :data-src="items[0].src" :alt="items[0].title" class="w-full h-full object-cover" />
                <span v-if="items[0].title" class="mosaique-label">{{ items[0].title }}</span>
            </div>
            <div
                v-if="items[1]"
                class="col-span-5 row-span-4 sm:col-span-4 sm:row-span-4 lg:col-span-3 xl:col-span-2 flex items-end"
            >
                <div class="mosaique-img-container thumbnail w-full h-3/4 sm:h-full" @click="() => openLightbox(1)">
                    <img
                        v-lazy-load
                        :data-src="items[1].src"
                        :alt="items[1].title"
                        class="w-full h-full object-cover"
                    />
                    <span v-if="items[1].title" class="mosaique-label">{{ items[1].title }}</span>
                </div>
            </div>
            <div
                v-if="items[2]"
                class="mosaique-img-container thumbnail col-span-6 col-start-3 row-span-4 sm:col-span-5 sm:row-span-4 lg:row-span-6"
                @click="() => openLightbox(2)"
            >
                <img v-lazy-load :data-src="items[2].src" :alt="items[2].title" class="w-full h-full object-cover" />
                <span v-if="items[2].title" class="mosaique-label">{{ items[2].title }}</span>
            </div>
            <div
                v-if="items[3]"
                class="mosaique-img-container thumbnail col-span-4 row-span-4 sm:col-span-4 sm:row-span-3 lg:row-span-4 sm:col-start-2 lg:col-start-1 lg:col-span-3 xl:col-span-2 xl:col-start-2"
                @click="() => openLightbox(3)"
            >
                <img v-lazy-load :data-src="items[3].src" :alt="items[3].title" class="w-full h-full object-cover" />
                <span v-if="items[3].title" class="mosaique-label">{{ items[3].title }}</span>
            </div>
            <div
                v-if="items[4]"
                class="mosaique-img-container thumbnail col-span-5 row-span-4 sm:col-span-4 sm:row-span-6"
                @click="() => openLightbox(4)"
            >
                <img v-lazy-load :data-src="items[4].src" :alt="items[4].title" class="w-full h-full object-cover" />
                <span v-if="items[4].title" class="mosaique-label">{{ items[4].title }}</span>
            </div>
            <div
                v-if="items[5]"
                class="mosaique-img-container thumbnail col-span-7 row-span-4 sm:col-span-3 sm:row-span-4 lg:col-span-4 lg:row-span-5"
                @click="() => openLightbox(5)"
            >
                <img v-lazy-load :data-src="items[5].src" :alt="items[5].title" class="w-full h-full object-cover" />
                <span v-if="items[5].title" class="mosaique-label">{{ items[5].title }}</span>
            </div>
            <div
                v-if="items[6]"
                class="mosaique-img-container thumbnail col-span-10 col-start-2 row-span-4 sm:col-start-3 sm:col-span-3 sm:row-span-3 lg:row-span-4 lg:col-start-1"
                @click="() => openLightbox(6)"
            >
                <img v-lazy-load :data-src="items[6].src" :alt="items[6].title" class="w-full h-full object-cover" />
                <span v-if="items[6].title" class="mosaique-label">{{ items[6].title }}</span>
            </div>
        </div>

        <LightboxGalleryCarousel ref="lightbox" :items="items" />
    </div>
</template>

<script>
import LightboxGalleryCarousel from '@/components/Carousels/LightboxGalleryCarousel/LightboxGalleryCarousel.vue';

export default {
    name: 'MosaiqueGalleryBlock',
    components: {
        LightboxGalleryCarousel,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
    },
    methods: {
        openLightbox(slideIndex) {
            this.$refs.lightbox.open(slideIndex);
        },
    },
};
</script>

<style scoped>
.mosaique-img-container {
    @apply rounded-[20px];
}
.mosaique-label {
    @apply absolute w-full bottom-0 left-0 pl-4 pb-4 font-Poppins font-semibold text-white capitalize text-xl hidden sm:inline-block z-1;
    &::after {
        content: '';
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 120px;
        background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.2) 70%);
        z-index: -1;
    }
}
</style>
