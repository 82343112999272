<template>
    <div class="grid grid-cols-12 base-responsive-gap">
        <div class="col-span-12 lg:col-span-10 separator-border-top-line" :class="{ 'lg:col-start-2': !alignLeft }">
            <h2 class="header-2 xl:max-w-2xl"><T :t="titleKey" /></h2>
        </div>
        <div
            class="col-span-12 whitespace-pre-line"
            :class="alignLeft ? 'lg:col-span-9 lg:col-start-2' : 'lg:col-span-8 lg:col-start-3'"
        >
            <T :t="descriptionKey" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Type8Block',
    props: {
        titleKey: {
            type: String,
            required: true,
        },
        descriptionKey: {
            type: String,
            required: true,
        },
        alignLeft: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
};
</script>

<style scoped></style>
