<template>
    <div>
        <div v-if="shouldDisplayTitle" class="absolute w-full h-full top-0 left-0 overflow-hidden z-0">
            <!-- wave -->
            <div
                class="z-1 absolute top-0 left-0 w-full h-full min-w-[60rem] sm:min-w-0 bg-contain lg:bg-cover bg-no-repeat h-[40rem] -translate-x-1/3 sm:translate-x-0"
                :style="`background-position: top center; filter: grayscale(100%); background-image: url(${require('~/assets/images/shapes/waves/wave-1-gray-200_3.png')})`"
            ></div>
        </div>

        <div v-if="shouldDisplayTitle" class="container pb-12 lg:pb-16 relative z-1">
            <h2 class="header-1 text-center">{{ title }}</h2>
        </div>

        <div v-if="$device.isMobileOrTablet">
            <div
                class="h-96 relative bg-cover bg-center flex items-center justify-center"
                v-lazy-background :lazy-background="require('~/assets/images/2d-map/2D-map-cropped.png')"
            >
                <div class="bg-white absolute w-full h-full opacity-70 z-1"></div>
                <Button
                    label="megnézem a térképet"
                    class="p-button-secondary z-2 relative"
                    @click.native="isMapOpened = true"
                />
            </div>
            <Portal selector="body">
                <Transition name="fade">
                    <div v-if="isMapOpened" id="2dMapWrapper" class="fixed top-0 left-0 w-screen h-screen z-50">
                        <div class="bg-white p-4">
                            <Button
                                label="Vissza"
                                icon="pi pi-chevron-left"
                                icon-pos="left"
                                class="p-button-secondary p-button-text"
                                @click.native="isMapOpened = false"
                            />
                        </div>
                        <Map2D :layers="layers" class="relative z-1" fullscreen />
                    </div>
                </Transition>
            </Portal>
        </div>
        <Map2D v-else :layers="layers" class="relative z-1" />
    </div>
</template>

<script>
import { disableBodyScroll, enableBodyScroll } from '@/static/scripts/body-scroll-lock.min';
import Map2D from '@/components/Map/Map2D.vue';

export default {
    name: 'Map2DAsEditableContentComponent',
    components: {
        Map2D,
    },
    props: {
        shouldDisplayTitle: {
            type: Boolean,
            required: false,
            default: false,
        },
        title: {
            type: String,
            required: true,
        },
        layers: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            isMapOpened: false,
        };
    },
    watch: {
        isMapOpened(value) {
            if (value) {
                disableBodyScroll('#2dMapWrapper');
            } else {
                enableBodyScroll('#2dMapWrapper');
            }
        },
    },
};
</script>

<style scoped></style>
