<template>
    <div>
        <div class="container separator-border-top-line mb-10">
            <div class="grid grid-cols-12 base-responsive-gap">
                <div class="col-span-12 sm:col-span-6 md:col-span-7">
                    <h2 class="header-1">{{ title }}</h2>
                    <div v-if="lead" class="lead mt-4 sm:mt-8 sm:mb-12" v-html="lead"></div>
                </div>
            </div>
        </div>

        <CardCarousel
            v-if="items.length"
            ref="cardCarousel"
            :items="items"
            large
            :is-initialized="activeSlideIndex !== null"
            :type="`tab-type-${carouselType}`"
            :programmatic-active-slide-index="activeSlideIndex"
            should-update-active-index-on-slide-change
            @update-active-index="onUpdateActiveIndex"
        />

        <div v-if="items.length" class="container">
            <div
                ref="panels"
                class="overflow-hidden relative transition-all"
                :style="`height: ${activePanelHeight}px;transition-duration: 600ms`"
            >
                <div
                    v-for="(item, i) in items"
                    :key="`item-panel-${i}`"
                    :ref="`panel-${i}`"
                    class="panel absolute w-full pb-4 pt-8 lg:pt-12"
                    :class="{
                        active: activeSlideIndex === i,
                        'z-0': activeSlideIndex !== i,
                        'z-1': activeSlideIndex === i,
                    }"
                >
                    <div class="grid grid-cols-12 gap-y-4 lg:gap-x-7">
                        <div class="panel__content col-span-12 md:col-span-7 md:px-8 container-overflow-forbidden">
                            <h3 class="header-3">{{ item.name }}</h3>
                            <div class="mt-4 wysiwyg-content" v-html="item.description"></div>
                        </div>
                        <div class="panel__image col-span-12 md:col-span-5 row-span-2">
                            <div class="mt-2 relative aspect-w-16 aspect-h-9">
                                <img
                                    :src="$getMediaImage(item.media, 'hero')"
                                    :alt="`${title} ${$t('generic.gallery_image_alt_text')} ${i}`"
                                    class="absolute w-full h-full object-cover object-center sm:object-left md:object-center base-responsive-rounding"
                                />
                            </div>
                        </div>

                        <div
                            v-if="item.label_cta && item.details_cta_url_primary"
                            class="panel__cta-container col-span-12 md:col-span-7 md:px-8"
                        >
                            <div class="mt-4">
                                <Button :to="item.details_cta_url_primary" target="_blank" :label="item.label_cta" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CardCarousel from '@/components/Carousels/CardCarousel/CardCarousel.vue';

export default {
    name: 'ServiceGroupBlock',
    components: {
        CardCarousel,
    },
    props: {
        // carouselType === 1: text goes onto image, blue bg opacity overlay on hover & active state
        // carouselType === 2: text goes under image, underlined text on hover & active state
        carouselType: {
            type: Number,
            required: false,
            default: 1,
            validator(value) {
                if (![1, 2].includes(value)) {
                    throw new Error('ServiceGroupBlock can either have carouselType 1 or carouselType 2');
                }
                return true;
            },
        },
        title: {
            type: String,
            required: true,
        },
        lead: {
            type: String,
            required: false,
            default: null,
        },
        items: {
            type: Array,
            required: true,
        },
        activeItemHash: {
            type: String,
            required: false,
            default: null,
        },
    },
    data() {
        return {
            activeSlideIndex: null,
            activePanelHeight: 0,
        };
    },
    watch: {
        activeItemHash(value) {
            if (!value) {
                return null;
            }

            const itemWithRouteHash = this.items.find((x) => {
                return x.anchor && x.anchor.replace(/^#/, '') === value.replace(/^#/, '');
            });

            if (itemWithRouteHash) {
                const index = this.items.indexOf(itemWithRouteHash);
                this.onUpdateActiveIndex(index);
            }
        },
        '$store.state.windowWidth'() {
            this.setContentBoxHeight();
        },
    },
    mounted() {
        this.setContentBoxHeight();
    },
    methods: {
        onUpdateActiveIndex(index) {
            this.activeSlideIndex = index;
            this.setContentBoxHeight();
        },
        setContentBoxHeight() {
            if (!this.items.length || this.activeSlideIndex === null) {
                return;
            }

            this.activePanelHeight = this.$refs[`panel-${this.activeSlideIndex}`][0].getBoundingClientRect().height;
        },
    },
};
</script>

<style scoped>
.panel__content,
.panel__image,
.panel__cta-container {
    opacity: 0;
    transition: all 0.4s ease;
}

.panel__image {
    transform: translateY(1rem);
    transition-delay: 100ms;
}

.panel.active {
    .panel__content,
    .panel__image,
    .panel__cta-container {
        opacity: 1;
    }
    .panel__content {
        transition-delay: 250ms;
    }

    .panel__image {
        transition-delay: 500ms;
        transform: translateY(0);
    }

    .panel__cta-container {
        transition-delay: 300ms;
    }
}
</style>
